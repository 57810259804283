import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.setButtonEvents();
  }

  setButtonEvents(){
    const buttons = this.element.querySelectorAll('.uc-pagination a');
    if (buttons.length < 1) return;

    buttons.forEach(button => {
      button.addEventListener('click', this.handleClick.bind(this));
    });
  }

  handleClick(event) {
    event.preventDefault();
    const url = event.currentTarget.href;
    fetch(url)
        .then(response => response.text())
        .then(html => {
            const parser = new DOMParser();
            const doc = parser.parseFromString(html, 'text/html');
            const cards = doc.querySelectorAll('.uc-card');
            const card = cards[cards.length - 1]; 
            if (card) {
                this.changeHTML(card)
            }
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
  }

  changeHTML(card){
    this.element.innerHTML = card.innerHTML;
    this.setButtonEvents();
  }
}
