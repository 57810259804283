<template>
<div data-controller="modal">
   <div class="uc-modal" data-action="click->modal#clickOutside" id="error_modal">
        <div class="uc-modal_content uc-message error w-auto" >
            <a href="javascript:void(0)" class="uc-message_close-button" data-action="click->modal#hide">
                <i class="uc-icon" aria-hidden="true">close</i>
            </a>
            <div class="uc-message_body">
                <h1 class="mb-24">
                    <i class="uc-icon warning-icon">warning</i> <span class="title"></span>
                </h1>
                <div>
                    <span class="description"></span>
                    <span>&nbsp;Si el problema persiste, favor comunicarlo a la <a href="http://mesadeservicios.uc.cl">mesa de servicios</a></span>
                </div>
            </div>
        </div>
   </div>
</div>
</template>