import { Controller } from "stimulus";

export default class extends Controller {
    static values = {
        url: String,
        filename: String
    }

    static targets = ["icon"];

    connect(){
        this.modal = document.getElementById("error_modal")
    }

    async download(){
        try {
            this.changeIcon(2)
            console.log(`Starting download from: ${this.urlValue}`);
            const response = await fetch(this.urlValue);
    
            if (!response.ok) {
                const errorBody = await response.json();
                const errorMessage = errorBody.error;
                throw new Error(errorMessage);
            }
    
            const blob = await response.blob();
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = this.filenameValue;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
    
            console.log("Download finished");
            this.changeIcon(0)
        } catch (error) {
            this.changeIcon(1)
            console.error("Error during download:", error.message);
            this.openModal(error.message)
        }
    }

    changeIcon(icon) {
        this.iconTargets.forEach((element) => {
            element.classList.add("d-none");
        });

        this.iconTargets[icon].classList.remove("d-none");
    }

    openModal(desc){
        this.modal.getElementsByClassName("title")[0].innerText = "Error al descargar"
        this.modal.getElementsByClassName("description")[0].innerText = desc
        this.modal.style.display = "block";
    }
}