<template>
  <aside class="mb-48 col-lg-3">
    <div class="uc-tabpanel" data-tabpanel data-controller="sidebar">
      <ul class="uc-vertical-tabs">
        <li class="uc-vertical-tabs_item" v-for="kind in kinds" :key="kind.id">
          <a
              class="uc-vertical-tabs_item-link"
              :id="'tab_' + kind.id"
              data-action='click->sidebar#changeTab'
              :data-tab-id="kind.id"
              :data-tab-title="kind.nombre"
              :data-tab-description="kind.descripcion"
          >
            {{ kind.nombre }}
          </a>
        </li>
      </ul>
    </div>
  </aside>
</template>
<script setup>
import {toRefs} from "vue";

const props = defineProps({
  kinds: {
    type: Object,
    required: true
  }
})
const { kinds } = toRefs(props);
</script>
