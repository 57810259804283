<template>
  <div class="table-responsive">
    <table class="uc-table-secondary w-100">
      <tbody>
      <tr v-if="activities?.data?.length === 0" class="text-center">
        <td colspan="100"> No hay información ingresada.</td>
      </tr>
      <tr v-for="activity in activities.data" class="row-link" >
        <td>{{ activity.periodo }}</td>
        <td>{{ activity.nombre_subtipo }}</td>
        <td>{{ activity.nombre }}</td>
        <td class="text-right text-nowrap">
          <a class="uc-btn btn-inline" :href="activity.url" >
            Ver detalle
            <i class="uc-icon" aria-hidden="true">
              chevron_right
            </i>
          </a>
        </td>
      </tr>
      </tbody>
    </table>
  </div>


  <div v-if="activities?.data?.length > 0" class="row mt-16">
    <div class="col-6">
      <a class="uc-btn btn-inline" :href="activities?.url">
        Ver todo
        <i class="uc-icon">chevron_right</i>
      </a>
    </div>
    <div class="col-6 d-flex justify-content-end">
      {{ activities?.data?.length }} de {{ activities?.metadata?.total_items }} actividades
    </div>
  </div>


</template>
<script setup>
import {toRefs} from "vue";

const props = defineProps({
  activities: {
    type: Object,
    required: true,
    default: []
  },
  userId: {
    type: Number,
    required: true
  },
  groupId: {
    type: Number,
    required: true
  }
})
const { userId, groupId, activities } = toRefs(props);
</script>
