<template>
  <CardSkeleton v-show="loading"/>
  <ErrorMessage v-if="error" :message="error_message"/>
  <div v-else v-for="group in groups" :key="group.id">
    <CardGroup :group="group" :userId="userId" :participantId="participantId"/>
  </div>
</template>
<script setup>
import {toRefs, watchEffect} from "vue";
import {useTaxonomy} from "../../api/taxonomy";
import CardGroup from "./CardGroup.vue";
import CardSkeleton from "./skeleton/CardSkeleton.vue";
import ErrorMessage from "../shared/ErrorMessage.vue";

const props = defineProps({
  kind: {
    type: Object,
    required: true
  },
  participantId: {
    type: Object,
    required: true
  },
  userId: {
    type: Number,
    required: true
  }
})
const { kind, } = toRefs(props);

const {groups,
    error,
    error_message,
    loading,
    fetchGroups} = useTaxonomy()
const fetchGroupsData = () => {
  fetchGroups(kind?.value?.id);
}

watchEffect(fetchGroupsData);
</script>
