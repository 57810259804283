<template>
  <ContentTitleViwDetail/>
  <div class="row">
    <ErrorMessage v-if="error" :message="error_message"/>
    <template v-else>
      <SidebarSkeketon v-if="loading" />
      <template v-else>
        <SidebarKinds :kinds="kinds"/>
        <div class="col-lg-9">
          <TabKinds :kinds="kinds" :userId="userId" :participantId="participantId"/>
        </div>
      </template>
    </template>
  </div>
</template>
<script setup>
import {useTaxonomy} from "../../api/taxonomy";
import {toRefs, watchEffect} from "vue";
import SidebarKinds from "./SidebarKinds.vue";
import TabKinds from "./TabKinds.vue";
import ContentTitleViwDetail from "./ContentTitleViwDetail.vue";
import SidebarSkeketon from "./skeleton/SidebarSkeketon.vue";
import ErrorMessage from "../shared/ErrorMessage.vue";
const props = defineProps({
  userId: {
    type: Number,
    required: true
  },
  participantId: {
    type: Number,
    required: true
  },
})
const { userId, participantId } = toRefs(props)

const {
  kinds,
  error,
  loading,
  error_message,
  fetchKinds} = useTaxonomy()
const fetchKindsData = () => {
  fetchKinds();
}

watchEffect(fetchKindsData);
</script>
