import { Controller } from "stimulus";
import flatpickr from "flatpickr";

export default class extends Controller {
  static targets = ["form", "textInputs", "dateInputs", "selectors", "resetButton"];

  connect() {
    this.addInputListeners();
    this.checkFormState(0);
  }

  resetForm() {
    this.resetTextInputs();
    this.resetDateInputs();
    this.resetSelectors();
    this.checkFormState();
  }

  resetTextInputs() {
    this.textInputsTargets.forEach(input => {
      input.value = "";
    });
  }

  resetDateInputs() {
    this.dateInputsTargets.forEach(input => {
      input.value = ""; // Reset the value
      const fp = flatpickr(input); // Initialize Flatpickr if not already initialized
      fp.clear(); // Clear the Flatpickr instance to reset the visible input
    });
  }

  resetSelectors() {
    this.selectorsTargets.forEach(select => {
      select.selectedIndex = 0;
    });
  }

  checkFormState(transition=500) {
    const isDefaultState = this.areTextInputsDefault() && this.areDateInputsDefault() && this.areSelectorsDefault();
    this.toggleResetButton(!isDefaultState, transition);
  }

  areTextInputsDefault() {
    return this.textInputsTargets.every(input => input.value === "");
  }

  areDateInputsDefault() {
    return this.dateInputsTargets.every(input => input.value === "");
  }

  areSelectorsDefault() {
    return this.selectorsTargets.every(select => select.selectedIndex === 0);
  }

  toggleResetButton(show, transition) {
    const button = this.resetButtonTarget;
    button.style.transition = `opacity ${transition}ms`;
    if (show) {
      button.style.opacity = '1';
    } else {
      button.style.opacity = '0';
    }
  }

  // Add event listeners to form inputs when the controller connects
  addInputListeners() {
    this.textInputsTargets.forEach(input => {
      input.addEventListener('input', () => this.checkFormState());
    });

    this.dateInputsTargets.forEach(input => {
      input.addEventListener('input', () => this.checkFormState());
    });

    this.selectorsTargets.forEach(select => {
      select.addEventListener('change', () => this.checkFormState());
    });
  }
}
