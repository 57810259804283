<template>
  <div class="uc-card mb-32">
    <div class="uc-card_body">
      <div class="row mt-40">
        <div class="col-12 ">
          <div class="d-flex">
            <h2 class="h4 mb-0">{{ group.nombre }}</h2>
          </div>
          <div class="uc-text-divider divider-primary my-20"></div>
        </div>

        <div class="col-12" v-show="group.descripcion">
          <p class="p-color--gray mb-1">
            {{ isExpanded ? group.descripcion : truncatedText }}
          </p>
          <button @click="toggleText" class="uc-btn btn-inline text-primary">
            {{ isExpanded ? 'Ver menos' : 'Ver más' }}
          </button>
        </div>

        <div class="">
          <TableSkeleton v-if="loading"/>
          <ErrorMessage v-if="error" :message="error_message"/>
          <TableActivities v-else :groupId="group.id" :userId="userId" :activities="activities"/>
        </div>

      </div>
    </div>
  </div>


</template>
<script setup>
import {computed, ref, toRefs, watchEffect} from "vue";
import TableActivities from "./TableActivities.vue";
import {useTaxonomy} from "../../api/taxonomy";
import TableSkeleton from "./skeleton/TableSkeleton.vue";
import ErrorMessage from "../shared/ErrorMessage.vue";

const props = defineProps({
  group: {
    type: Object,
    required: true
  },
  userId: {
    type: Number,
    required: true
  },
  participantId: {
    type: Number,
    required: true
  },
  limit: {
    type: Number,
    default: 200 // Longitud del texto que se mostrará inicialmente
  }
})
const { group, userId, participantId } = toRefs(props);

const {activities,
    loading,
    error,
    error_message,
    fetchActivities}= useTaxonomy();
const fetchActivitiesData = () => {
  fetchActivities({participantId: participantId.value, userId: userId.value, groupId: group?.value?.id, items: 3});
}

const isExpanded = ref(false);

const truncatedText = computed(() => {
  return props.group?.descripcion.length > props.limit
      ? props.group?.descripcion.substring(0, props.limit) + '...'
      : props.group?.descripcion;
});

const toggleText = () => {
  isExpanded.value = !isExpanded.value;
};

watchEffect(fetchActivitiesData);
</script>
