import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import { Spanish } from 'flatpickr/dist/l10n/es.js';

document.addEventListener('DOMContentLoaded', () => {
  flatpickr('.datepicker', { 
    locale: Spanish,
    dateFormat: 'Y-m-d', // Formato que recibe la vista (yyyy-mm-dd)
    altInput: true,
    altFormat: 'd-m-Y', // Formato que se muestra al usuario
    allowInput: false
  });
});