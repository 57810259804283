import { Controller } from "stimulus";

export default class extends Controller {
    static values = {
        type: String
      }

    connect() {
        const timeoutDuration = this.getTimeoutDuration(this.typeValue);
        setTimeout(() => {
            this.close();
        }, timeoutDuration);
    }

    getTimeoutDuration(alertType) {
        const strategy = {
            'danger': 10000,  // alerta blanca
            'error': 20000,   // alerta roja
            'success': 5000,  // alerta verde
        };

        // Devuelve el valor correspondiente o 5000 ms por defecto si no existe el tipo
        return strategy[alertType] || 5000; 
    }

    close() {
        this.element.style.transition = 'opacity 500ms';
        this.element.style.opacity = '0';

        setTimeout(() => {
            this.element.remove();
        }, 500);
    }
}