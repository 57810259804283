import { ref } from 'vue';
import axios from 'axios';

const apiClient = axios.create({
    baseURL: window.location.origin + '/api',
    headers: {
        'Content-Type': 'application/json'
    }
});

export function useUsers() {
    const summaryHtml = ref("");
    const documents = ref([])
    const selfAssessments = ref([])
    const loading = ref(false);
    const error = ref(null);

    const fetchSummaryHtml = async (participantId) => {
        loading.value = true
        const url = `/participants/${participantId}/summary_html`;

        const request = async () => {
            try {
                const response = await apiClient.get(url);

                const userSummaryDateElement = document.getElementById('user-summary-date');
                if (userSummaryDateElement) {
                    // Adjust the UTC date to Chilean time
                    const [day, month, year, hours, minutes] = response.data.date.match(/\d+/g);
                    const utcDate = new Date(Date.UTC(year, month - 1, day, hours, minutes));
                    const date = utcDate.toLocaleString('es-CL', { timeZone: 'America/Santiago', hour12: false });
                    userSummaryDateElement.textContent = date.slice(0, -3);
                }

                summaryHtml.value = processHtmlString(response.data.content);
                loading.value = false
            } catch (err) {
                if (err.response && err.response.status === 404) {
                    setTimeout(request, 2000);
                } else {
                    error.value = err;
                }
            }
        };
        await request();
    };

    const fetchDocuments = async (participantId) => {
        try{
            const url = `/participants/${participantId}/documents`;
            const response = await apiClient.get(url);
            documents.value = response.data;
        } catch (e){

        }
    }
    const fetchSelfAssessments = async (participantId) => {
        try{
            const url = `/participants/${participantId}/self_assessments`;
            const response = await apiClient.get(url);
            selfAssessments.value = response.data;
        } catch (e){

        }
    }
    return {
        documents,
        selfAssessments,
        summaryHtml,
        loading,
        error,
        fetchSummaryHtml,
        fetchDocuments,
        fetchSelfAssessments,
    };
}

function processHtmlString(originalHtml) {
    if (window.location.pathname.includes('/evaluado/')) {
        // Parse the original HTML string into a DOM-like structure
        let parser = new DOMParser();
        let doc = parser.parseFromString(originalHtml, 'text/html');

        // Find elements with the class general_empty_msg and add d-none class
        doc.querySelectorAll('.general_empty_msg').forEach(element => {
            element.classList.add('d-none');
        });

        // Find elements with the class evaluated_decree_empty_msg and remove d-none class
        doc.querySelectorAll('.evaluated_decree_empty_msg').forEach(element => {
            element.classList.remove('d-none');
        });

        // Convert the modified DOM back to an HTML string
        return doc.body.innerHTML;
    }

    // Convert the modified DOM back to an HTML string
    return originalHtml;
  }

