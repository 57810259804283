import { Controller } from "stimulus";

export default class extends Controller {
    connect(){
        this.modal = this.element.querySelector('.uc-modal');
    }

    reveal(){
        this.modal.style.display = "block";
    }
    
    hide(){
        this.modal.style.display = "none";
    }

    clickOutside(e){
        if (e.target.parentNode != this.element) return;

        this.hide();
    }
}