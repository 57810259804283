<template>
  <div class="uc-card width-500">
    <div class="uc-card_body">
      <h3 class="h5">
        {{ document.file_name }}
      </h3>
      <div class="uc-text-divider divider-primary my-12"></div>
      <p class="text-size--sm text-color--gray text--condensed">
        Subido por {{ document.ownership }}, el {{ document.created_at }}
      </p>
      <p class="no-margin">
        {{ isExpanded ? document.description : truncatedText }}
        <button @click="toggleText"  class="uc-btn btn-inline" v-show="document.description.length >= limit">
          {{ isExpanded ? 'Ver menos' : 'Ver más' }}
        </button>
      </p>
      <div class="text-right mt-auto">
        <ButtonDownload :document="document" />
      </div>
    </div>
  </div>
</template>
<script setup>
import {computed, ref, toRefs} from "vue";
import ButtonDownload from "./ButtonDownload.vue";

const props = defineProps({
  document: {
    type: Object,
    required: true
  },
  limit: {
    type: Number,
    default: 30 // Longitud del texto que se mostrará inicialmente
  }
})
const { document, limit } = toRefs(props);
const isExpanded = ref(false);

const truncatedText = computed(() => {
  return props.document?.description.length > props.limit
      ? props.document?.description.substring(0, props.limit) + '...'
      : props.document?.description;
});

const toggleText = () => {
  isExpanded.value = !isExpanded.value;
};

</script>
<style>
.width-500 {
  max-width: 500px
}
</style>
