<template>
  <div class="uc-card mb-4 mt-4">
    <div class="uc-card_body text-center">
      <p>
        <i class="uc-icon warning-icon">warning</i> {{ message }}
      </p>
      <p class="no-margin">
        Pruebe de nuevo. Si el problema persiste, reporte este incidente en: <a href="https://mesadeservicios.uc.cl/">mesadeservicios.uc.cl</a>
      </p>
    </div>
  </div>
</template>
<script setup>
import {toRefs} from "vue";

const props = defineProps({
  message: {
    type: String,
    required: true
  }
})
const { message } = toRefs(props)
</script>
