import { ref } from 'vue';
import axios from 'axios';

const apiClient = axios.create({
    baseURL: window.location.origin + '/api',
    headers: {
        'Content-Type': 'application/json'
    }
});

export function useDocuments() {
    const document = ref([])
    const loading = ref(false);
    const error = ref(null);

    const fetchDownload = async (documentId) => {
        try{
            loading.value = true
            const url = `/documents/${documentId}/download`;
            const response = await apiClient.get(url,{
                responseType: 'blob' // Esto es importante para manejar archivos binarios
            });
        } catch (e){
            error.value = e
            loading.value = false
        }
    }
    return {
        document,
        loading,
        error,
        fetchDownload,
    };
}
