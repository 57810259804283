<template>
  <div class="row mb-32">
    <div class="col-12 col-lg">
      <div class="gap-3 d-flex flex-column">
        <div class="d-flex justify-content-between flex-column flex-lg-row">
          <h1 id="tabTitle"></h1>
        </div>
        <div class="row">
          <p class="col-12 p-color--gray mb-0 " id="tabDescription"></p>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
</script>
