<template>

  <div v-for="kind in kinds" :id="'tab_container_' + kind.id" class="d-none" :key="kind.id">
    <ListCardGroups :kind="kind" :userId="userId" :participantId="participantId" />
  </div>
</template>
<script setup>

import {toRefs} from "vue";
import ListCardGroups from "./ListCardGroups.vue";

const props = defineProps({
  kinds: {
    type: Object,
    required: true
  },
  participantId: {
    type: Object,
    required: true
  },
  userId: {
    type: Number,
    required: true
  }
})
const { kinds, userId, participantId} = toRefs(props);
</script>
