import {ref, toRaw} from 'vue';
import axios from 'axios';

const apiClient = axios.create({
    baseURL: window.location.origin + '/api',
    headers: {
        'Content-Type': 'application/json'
    }
});

export function useTaxonomy() {
    const kinds = ref([])
    const groups = ref([])
    const activities = ref([])
    const loading = ref(true);
    const error = ref(false);
    const error_message = ref(null);

    const fetchKinds = async () => {
        const request = async ()=>{
            try{
                loading.value = true
                const url = `/taxonomy/kinds`;
                const response = await apiClient.get(url);
                kinds.value = response.data
                error.value = false
                error_message.value = null
            } catch (e){
                loading.value = false
                error.value = true
                error_message.value = e.response.data.error
                setTimeout(request, 3000);
            } finally {
                loading.value = false
            }
        }
        await request();
    }

    const fetchGroups = async (kindId) => {
        const request = async ()=>{
            try{
                loading.value = true
                const url = `/taxonomy/kinds/${kindId}/groups`;
                const response = await apiClient.get(url);
                groups.value = response.data
                error.value = false
                error_message.value = null
            } catch (e){
                loading.value = false
                error.value = true
                error_message.value = e.response.data.error
                setTimeout(request, 3000);
            } finally {
                loading.value = false
            }
        }
        await request();
    }
    const fetchActivities = async (params) => {
        const request = async ()=>{
            
            try{
                loading.value = true
                const url = `/taxonomy/activities?participant_id=${params.participantId}&group_id=${params.groupId}&items=${params.items}`;
                const response = await apiClient.get(url);
                activities.value = response.data
                error.value = false
                error_message.value = null
            } catch (e){
                loading.value = false
                error.value = true
                error_message.value = e.response.data.error
                setTimeout(request, 3000);
            } finally {
                loading.value = false
            }
        }

        await request();
    }


    return {
        kinds,
        groups,
        activities,
        loading,
        error,
        error_message,
        fetchKinds,
        fetchGroups,
        fetchActivities
    };
}
