import { Controller } from "stimulus";

var last_val = "";

export default class extends Controller {
    static targets = [ 'userRutInput'];

    connect(){
       this.userRutInputTarget.value = formatRut(this?.userRutInputTarget?.value);
       this.userRutInputTarget.innerHTML = formatRut(this?.userRutInputTarget?.innerHTML);
    }
    setRut(event){
        const element = event.target;
        var value = element.value
        if (is_rut(value)){
            value = cleanRut(value)
            element.value = formatRut(value)
        } else if (is_rut(last_val)) {
            element.value = remove_format(value)
        }
        last_val = value
    }
}

function remove_format(value){
    return value.replace(/[.\-]/g, '');
}

function is_rut(value) {
    // Remove unwanted characters
    const cleanedValue = value.replace(/[.\-kK]/g, '');

    // Check if the remaining string is numeric
    return is_numeric(cleanedValue);
}

function is_numeric(str){
    return /^\d+$/.test(str);
}

function cleanRut(inputString) {
    var cleanedString = inputString.replace(/[^0-9kK]/g, '');
    cleanedString = cleanedString.replace(/[kK](?!$)/g, '');
    return cleanedString;
}

function formatRut(rut){
    try {
        if (rut.length <= 1) return rut;

        var lastChar =  rut[rut.length - 1];
        var cutRut = rut.slice(0,-1)

        var formatted = cutRut.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

        return formatted+'-'+lastChar
    } catch (error) {
        return rut;
    }
}

function customMatcher(params, data) {
    // Modify the matcher function to search against the raw value
    if ($.trim(params.term) === '') {
      return data; // Return all options if no search term is entered
    }

    // Search logic for terms without "." or "-"
    var termWithoutSymbols = params.term.replace(/[.-]/g, '');
    var dataWithoutSymbols = data.text.replace(/[.-]/g, '');

    if (dataWithoutSymbols.indexOf(termWithoutSymbols) > -1) {
      return data; // Return matching options
    }
    return null; // Return no matches
}
