<template>
  <div class="table-responsive placeholder-glow">
    <table class="uc-table-secondary w-100">
      <tbody>
      <tr>
        <td><span class="placeholder col-12 bg-dark"></span></td>
        <td><span class="placeholder col-12 bg-dark"></span></td>
        <td><span class="placeholder col-12 bg-dark"></span></td>
        <td class="text-right text-nowrap">
          <a>
            <span class="placeholder col-5 bg-primary"></span>
          </a>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="row mt-16">
    <div>
      <a >
        <span class="placeholder col-1 bg-primary"></span>
      </a>
    </div>
  </div>
</template>
