// controllers/switch_controller.js
import { Controller } from 'stimulus'
import { createApp } from 'vue'
import ModalErrorChangeStatus from '../components/qualification_processes/ModalErrorChangeStatus.vue'
import AlertMessage from "../components/shared/AlertMessage.vue";
import axios from "axios";

export default class extends Controller {
    static targets = ["button", "modal", "switchlabel", "switchinput","switchbutton","titlelabel"]

    async changeStatus(event) {
        try{
            const url = this.buttonTarget.dataset.url;

            const response = await axios.put(url, null, {
                headers: {
                'X-CSRF-Token': csrfToken()
            }})
       
            this.switchinputTarget.checked = statusChecked(response?.data?.qualification_process?.status)
            this.switchlabelTarget.innerHTML = response?.data?.qualification_process?.status
            this.showAlertMessage( response.data.message, 'success', 'check_circle',);
            var status = response?.data?.qualification_process?.status
            if (status == "Activo"){
                this.switchbuttonTarget.innerHTML ="Desactivar proceso"
                this.buttonTarget.innerHTML ="Sí, desactivar"
                this.titlelabelTarget.innerHTML ="¿Está seguro/a de que quiere desactivar este proceso de calificación?"
            }
            else{
                this.switchbuttonTarget.innerHTML ="Activar proceso"
                this.buttonTarget.innerHTML ="Sí, activar"
                this.titlelabelTarget.innerHTML ="¿Está seguro/a de que quiere activar este proceso de calificación?"
            }
        } catch (e){
            this.switchinputTarget.checked = statusChecked(e.response?.data?.qualification_process?.status)
            this.switchlabelTarget.innerHTML = e.response?.data?.qualification_process?.status
            this.showErrorModal(e.response.data.error);
        }
    }

    showErrorModal(error) {
        const modalApp = createApp(ModalErrorChangeStatus, { error });
        modalApp.mount(this.modalTarget);
        document.querySelector('.uc-modal').style.display = 'none';
        this.modalTarget.querySelector('.uc-modal').style.display = 'block';
    }

    showAlertMessage(message, typeAlert, icon) {
        document.querySelector('.uc-modal').style.display = 'none';
        const modalApp = createApp(AlertMessage, { message, typeAlert, icon });
        const alertDiv = document.querySelector('#js-alert-container')
        modalApp.mount(alertDiv);
        alertDiv.scrollIntoView({ block: "end", behavior: "smooth" });
    }

}
const csrfToken = () => {
    const meta = document.querySelector('meta[name=csrf-token]');
    const token = meta && meta.getAttribute('content');

    return token ?? false;
}

const statusChecked = (value) => {
    const strategies = {
        'Activo': true,
        'Inactivo': false,
    }
    return strategies[value]
}
