<template>
<aside class="mb-48 col-lg-3">
  <div class="uc-tabpanel">
    <ul class="uc-vertical-tabs placeholder-glow">
      <li class="uc-vertical-tabs_item placeholder-glow">
        <a class="uc-vertical-tabs_item-link active">
          <span class="placeholder col-12"></span>
        </a>
      </li>
      <li class="uc-vertical-tabs_item placeholder-glow">
        <a class="uc-vertical-tabs_item-link">
          <span class="placeholder col-8"></span>
          <span class="placeholder col-4"></span>
        </a>
      </li>
      <li class="uc-vertical-tabs_item placeholder-glow">
        <a class="uc-vertical-tabs_item-link">
          <span class="placeholder col-3"></span>
        </a>
      </li>
    </ul>
  </div>
</aside>
</template>
