<template>
  <div class="uc-tabpanel" data-tabpanel>
    <ul class="uc-tabs d-none d-lg-flex">
      <li class="uc-tabs_item">
        <a href="javascript:void(0);"
           class="uc-tabs_item-link"
           data-tabtarget="tab-01"
           data-tabactive>
          Resumen
        </a>
      </li>
      <li class="uc-tabs_item">
        <a
            href="javascript:void(0);"
            class="uc-tabs_item-link"
            data-tabtarget="tab-02"
        >Documentos</a>
      </li>
      <li class="uc-tabs_item">
        <a
            href="javascript:void(0);"
            class="uc-tabs_item-link"
            data-tabtarget="tab-03"
        >Auto-reporte (cualitativo)</a>
      </li>
      <li class="uc-tabs_item">
        <a
            href="javascript:void(0);"
            class="uc-tabs_item-link"
            data-tabtarget="tab-04"
        >Detalle</a>
      </li>
    </ul>
    <div class="uc-tab-body">
      <div data-tab="tab-01">
        <SummaryContent :userId="userId" :participantId="participantId" />
      </div>
      <div data-tab="tab-02">
        <ListDocuments :participantId="participantId" />
      </div>
      <div data-tab="tab-03">
        <SelfAssessments :userId="userId" :participantId="participantId" />
      </div>
      <div data-tab="tab-04">
        <ViewDetail :participantId="participantId" :userId="userId" />
      </div>
    </div>
  </div>
</template>

<script setup>
import {toRefs} from "vue";
import SummaryContent from "./SummaryContent.vue";
import ListDocuments from "./ListDocuments.vue";
import ViewDetail from "./ViewDetail.vue";
import SelfAssessments from "./SelfAssessments.vue";

const props = defineProps({
  userId: {
    type: Number,
    required: true
  },
  participantId: {
    type: Number,
    required: true
  },
  selfAssessments: {
        type: Array,
        required: true
      }
})
const { userId, participantId } = toRefs(props)
const { selfAssessments } = toRefs(props)
</script>
