<template>
  <div class="uc-card">
    <h3 class="uc-card_header">  {{ item.kind }}</h3>
    <div class="uc-card_body">
      <p> {{ item.text }}
      </p>
    </div>
  </div>
</template>

<script setup>
import {computed, ref, toRefs} from "vue";

const props = defineProps({
  item: {
    type: Object,
    required: true
  },
})
const { item } = toRefs(props);

</script>
<style>

</style>
