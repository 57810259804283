import { Controller } from "stimulus";
import {createApp} from "vue";
import ModalConfirm from "../components/qualification_processes/ModalConfirm.vue";
import axios from "axios";

export default class extends Controller {
    static targets = ["addUserSubmit", "modal", "userRutInput"]
    static values = {
        confirmTitle: String,
        confirmDescription: String,
        userRut: String,
        searchUrl: String,
        userId: Number,
    }

    connect() {
        this.disableForm()
    }


    submit(event){
        const modalApp = createApp(ModalConfirm, {
            title: this.confirmTitleValue,
            description: this.confirmDescriptionValue,
            user: event?.params?.user,
            valueNotFound: event?.params?.valueNotFound,
            locale: event?.params?.locale
        });

        modalApp.mount(this.modalTarget);
        this.modalTarget.querySelector('.uc-modal').style.display = 'block';
    }

    disableForm() {
        if (this.userIdValue !== 0){
            this.addUserSubmitTarget.disabled = false
        } else {
            this.addUserSubmitTarget.disabled = true
        }

        if (this.userRutInputTarget.value != this.userRutValue){
            this.addUserSubmitTarget.disabled = true
        }

        if (this.userRutInputTarget.value === this.userRutValue){
            this.addUserSubmitTarget.disabled = false
        }

        const strategies = {
            [(this.userRutInputTarget.value != this.userRutValue)] : true,
            [(this.userIdValue === 0)]: true
        }

        this.addUserSubmitTarget.disabled = strategies[true] || false
    }

    searchByRut() {
        const inputValue = this.userRutInputTarget?.value?.replace(/[.\-]/g, '');
        const url = new URL(this.searchUrlValue);
        url.searchParams.set("rut", inputValue);

        window.location.href = url.toString();
    }
}
