import { Controller } from "stimulus";
  
export default class extends Controller {
  connect() {
    setFirst()
  }

  changeTab(event) {
    event.preventDefault();
    const tabId = event.target.dataset.tabId;
    hideAllContainers();
    showKind(tabId);
    setActiveTab(event);
    setTitle(event);
  }
}

function setFirst(){
  if (isFirstLoaded()) {
    var tabElement = document.getElementById(`tab_${first_id()}`);
    tabElement.click();
  } else { setTimeout(setFirst, 50); }
}

function hideAllContainers(){
  const tabContainers = document.querySelectorAll('[id^="tab_container_"]');
  tabContainers.forEach(card => {
    card.classList.add('d-none');
  });
}

function showKind(tabId){
  const selectedCard = document.getElementById(`tab_container_${tabId}`);
  if (selectedCard) {
    selectedCard.classList.remove('d-none');
  }
}

function setActiveTab(event) {
  const activeTab = document.querySelector('.uc-vertical-tabs_item-link.active');
  if (activeTab) {
    activeTab.classList.remove('active');
  }

  const eventElement = event.currentTarget;
  eventElement.classList.add('active');
}

function setTitle(event){
  var tabTitle = document.getElementById("tabTitle");
  var tabDescription = document.getElementById("tabDescription");

  const title = event.target.dataset.tabTitle;
  const description = event.target.dataset.tabDescription;

  tabTitle.innerHTML = title;
  tabDescription.innerHTML = description;
}

function isFirstLoaded(){
  const tabContainers = document.querySelectorAll(`[id^="tab_container_${first_id()}"]`);
  return tabContainers.length > 0;
}

function first_id(){
  const url = window.location.search;
  const searchParams = new URLSearchParams(url);
  const tab = searchParams.get('tab');

  if (!tab) return '1';

  return tab;
}