<script setup>
 import {useUsers} from "../../api";
 import {toRefs, watchEffect} from "vue";
 import SkeletonLoader from "./SkeletonLoader.vue";

 const props = defineProps({
   participantId: {
     type: Number,
     required: true
   }
 })

 const { participantId } = toRefs(props)
 const {
   loading,
   summaryHtml,
   fetchSummaryHtml
 } = useUsers()

 const fetchContent = () => {
   console.log(participantId.value)
   fetchSummaryHtml(participantId.value)
 }

 watchEffect(fetchContent);
</script>

<template>
  <template v-if="loading">
    <SkeletonLoader />
  </template>
  <div v-else v-html="summaryHtml"></div>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');


.francesa {
  padding-left: 0px;
  text-indent: -40px;
}
.francesa-grupo {
  margin-left: 81px;
}
.pagina {
  margin-left: 40px; /* Margen izquierdo en píxeles */
  margin-right: 36px; /* Margen derecho en píxeles */
}

.table-class {
  color: #000000;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5em;
}

.page-number::before {
  counter-increment: page;
  content: counter(page);
}

.container-footer {
  align-items: right;
  text-align: right;
}
.message-date-edit {
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.container {
  position: relative;
  width: 100%;
  display: grid;
}

.container .profileText {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.after {
  position: absolute;
  top: 0;
  width: 20px;
  height: 1250px;
  background-color: rgba(0, 0, 0, 0.25); /* Color negro al 25% de opacidad */
}

.ImgBx {
  position: relative;
  width: 140px;
  height: 260px;
}

h2 {
  color: #397dda;
  font-weight: bold;
  margin-top: 250px;
  text-align: right;
  line-height: 1.4em;
}

h2 span {
  color: #397dda;
  font-style: normal;
  margin-top: 10px;
  text-align: right;
  font-weight: 300;
  line-height: 1.4em;
}

.container .lado_izquierdo {
  position: relative;
  background: #fff;
  padding: 40px;
  overflow: auto;
  flex: 0 0 auto;
}

.container .lado_derecho {
  position: relative;
  background: #ffffff;
  padding: 40px;
  justify-content: space-around; /* Ajusta el espaciado entre listas */
}


.categoria {
  background-color: #fff;
  margin-bottom: 20px;
}

h2 {
  margin-bottom: 15px;
}

.categoria .subtitulo {
  padding-top: 1px;
  margin-bottom: 5px; /* Ajusta el espacio inferior */
}


.categoria h3 {
  margin-bottom: 5px;
  color: #397dda;
  font-weight: bold;
  text-align: left;
  line-height: 1.4em;
}

.categoria h4 {
  color: #000000;
  text-align: left;
  line-height: 1.4em;
  font-weight: 400;
  margin-bottom: 5px; /* Espacio entre el título y la lista */

}

.categoria h5 {
  color: #5b595c;;
  text-align: left;
  line-height: 1.4em;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 5px; /* Espacio entre el título y la lista */

}

.categoria .list ul {
  list-style-type: none; /* Elimina la viñeta/punto */
  padding-left: 0; /* Elimina el relleno predeterminado de la lista */
  margin-bottom: 25px;


}

.categoria .list ul li {
  margin-bottom: 5px; /* Espacio entre elementos de lista */

}

.categoria .año {
  color: #000000;
  margin-top: 15px;
  font-style: normal;
}


.categoria .separador {
  color: #397dda;
  font-style: normal;
  font-weight: 700;

}

.categoria .educacion_cargo {
  color: #000000;
  font-style: normal;
}

.categoria .fecha {
  color: #000000;
  font-style: normal;
}

.container1 {

}

.banner {
  position: relative;
  background: #fff;
  padding: 40px;
  overflow: auto;
  flex: 0 0 auto;
  height: 300px;
}

.profileText {
  position: relative;
  display: flex;
  flex-direction:row;
  align-items: left;
}

.banner::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 100%;
  background-color: #397dda;
}

.profileText {
  display: flex;
  flex-direction: row-reverse; /* Invierte la direcciÃ³n de los elementos */
  align-items: center; /* Centra verticalmente los elementos */
  justify-content: space-between; /* Alinea los elementos al final del contenedor */
}

.profileText .ImgBx {
  position: relative;
  width: 200px;
  height: 200px;
  margin-left: 0px;
  object-fit: cover;
}

.profileText .ImgBx img {
  position: absolute;
  top: 0%;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}

.profileText h2 {
  flex: 1; /* Ocupa todo el espacio disponible */
  color: #397dda;
  font-weight: bold;
  line-height: 1.4em;
  text-align: right;
  margin-left: 20px; /* Espacio entre la imagen y el texto */
}

.container1 .continuacion {
  position: relative;
  background: #ffffff;
  justify-content: space-around; /* Ajusta el espaciado entre listas */
}

.container1 .continuacion .categoria {
  background-color: #fff;
  margin-bottom: 20px;
}

.container1 .continuacion .categoria h2 {
  margin-bottom: 15px;
}

.container1 .continuacion .categoria .subtitulo {
  padding-top: 1px;
  margin-bottom: 5px; /* Ajusta el espacio inferior */
}


.container1 .continuacion .categoria h3 {
  margin-bottom: 5px;
  color: #397dda;
  font-weight: bold;
  text-align: left;
  line-height: 1.4em;
}

.container1 .continuacion .categoria h4 {
  color: #000000;
  text-align: left;
  line-height: 1.4em;
  font-weight: 400;
  margin-bottom: 5px; /* Espacio entre el tÃ­tulo y la lista */

}

.container1 .continuacion .categoria h5 {
  color: #5b595c;;
  text-align: left;
  line-height: 1.4em;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 5px; /* Espacio entre el tÃ­tulo y la lista */

}

.container1 .continuacion .categoria .subtipo h5{
  color: #397dda;
  text-align: left;
  line-height: 1.4em;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 5px; /* Espacio entre el tÃ­tulo y la lista */
}

.container1 .continuacion .categoria .list ul {
  list-style-type: none; /* Elimina la viÃ±eta/punto */
  padding-left: 0; /* Elimina el relleno predeterminado de la lista */
  margin-bottom: 25px;
}

.container1 .continuacion .categoria .list ul li {
  margin-bottom: 5px; /* Espacio entre elementos de lista */
}

.container1 .continuacion .categoria .aÃ±o {
  color: #000000;
  margin-top: 15px;
  font-style: normal;
}


.container1 .continuacion .categoria .separador {
  color: #397dda;
  font-style: normal;
  font-weight: 700;
}

.container1 .continuacion .categoria .educacion_cargo {
  color: #000000;
  font-style: normal;
}

.container1 .continuacion .categoria .fecha {
  color: #000000;
  font-style: normal;
}
</style>
