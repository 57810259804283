<template>
  <SummaryContent v-if="isCurrentUser" :userId="userId" :participantId="participantId" />
  <Tabs v-else :participantId="participantId" :userId="userId" :selfAssessments="selfAssessments" />
</template>

<script setup>
import SummaryContent from "../components/evaluated_detail/SummaryContent.vue";
import Tabs from "../components/evaluated_detail/Tabs.vue";
const summaryElement = document.getElementById('summary');
const userId = parseInt(summaryElement.getAttribute('data-user-id'));
const participantId = parseInt(summaryElement.getAttribute('data-participant-id'));
const currentUserId =  parseInt(summaryElement.getAttribute('data-current-user-id'));
const isCurrentUser = (userId === currentUserId)
</script>

<style scoped>
</style>
