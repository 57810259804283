<template>
  <div class="container">
    <div class="row">
      <div v-if="documents.length === 0 ">
        <div class="uc-card">
          <div class="uc-card_body--xl">
            <p class="text-center">
              <i class="uc-icon icon-size--sm">info</i>
              Actualmente no existe ningún documento adjunto por parte del usuario evaluado
            </p>
          </div>
        </div>
      </div>
      <div class="col-4 mb-4" v-for="document in documents" :key="document.id">
        <Document :document="document" />
      </div>
    </div>
  </div>
  <ErrorModal/>
</template>
<script setup>
import {toRefs, watchEffect} from "vue";
import {useUsers} from "../../api";
import Document from "./Document.vue";
import ErrorModal from "./ErrorModal.vue";

const props = defineProps({
  participantId: {
    type: Number,
    required: true
  }
})
const { participantId } = toRefs(props);
const { fetchDocuments, documents } = useUsers();
const fetchDocumentsData = () => {
  fetchDocuments(participantId.value)
}

watchEffect(fetchDocumentsData);
</script>
