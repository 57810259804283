<template>
  <template v-if="document.expiry_date">
    <div class="uc-tooltip uc-badge uc-badge--text uc-badge--inline mx-8" @mouseover="showTool">
      Documento expirado
      <div class="tooltip" v-show="tool">
        <span class="text" >
          Este archivo fue subido en la fecha {{ document.created_at }}. Los archivos expiran luego de 2 años, por lo que no está disponible para su descarga.
        </span>
      </div>
    </div>
  </template>
  <div
    v-else
    data-controller="download"
    :data-download-url-value="`/evaluador/documentos/${ document.id }/download`"
    :data-download-filename-value="document.file_name"
    data-action='click->download#download'
    style="cursor: pointer;"
  >
    <i class="uc-icon" data-download-target="icon">download</i>
    <i class="uc-icon d-none" data-download-target="icon">warning</i>
    <i class="spinner-border text-primary d-none" data-download-target="icon" style="width: 1rem; height: 1rem;"></i>
  </div>
</template>
<script setup>
import {toRefs} from "vue";
import {useDocuments} from "../../api";

const props = defineProps({
  document: {
    type: Object,
    required: true
  }
})
const { document } = toRefs(props);
const tool = defineModel({ required: false })


const showTool = () =>{
  tool.value = true
  setTimeout(() => tool.value = false, 3000)
}

const { fetchDownload, loading, error } = useDocuments();

const handleDownload = () => {
  fetchDownload(document?.value?.id);
};
</script>

<style>
.uc-tooltip {
  position: absolute;
  display: inline-block;
}

.tooltip {
  text-align: center;
  padding: 16px 24px;
  border-radius: 2px;

  width: 300px;
  bottom: 100%;
  left: 50%;
  margin-left: -200px;

  opacity: 1;
  transition: opacity 1s;

  position: absolute;
  z-index: 1;

  background-color:#fff;
  color:#000;
  box-shadow:0 0 20px rgba(0,0,0,.18);
}

.text::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
}
</style>
