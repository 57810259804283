import { Controller } from "stimulus";

const name_field = document.getElementById("document_name");

export default class extends Controller {
  set_name(event){
    const file = event.target.files[0]
    if (name_field.value != "") return;

    const {name} = splitFileName(file.name)
    name_field.value = name
  }
}

function splitFileName(fileName) {
  const lastDotIndex = fileName.lastIndexOf('.');
  if (lastDotIndex === -1) {
    return { name: fileName, extension: '' };
  }

  const name = fileName.substring(0, lastDotIndex);
  const extension = fileName.substring(lastDotIndex + 1);

  return { name, extension };
}
