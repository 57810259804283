<template>
  <div class="placeholder-glow">
    <div class="placeholder col-3 bg-primary"></div>
    <div class="placeholder col-12 bg-dark"></div>
    <div class="placeholder col-12 bg-dark"></div>
    <div class="placeholder col-3 bg-primary"></div>
    <div class="placeholder col-12 bg-dark"></div>
    <div class="placeholder col-12 bg-dark"></div>
    <div class="placeholder col-3 bg-primary"></div>
    <div class="placeholder col-12 bg-dark"></div>
    <div class="placeholder col-12 bg-dark"></div>
    <div class="placeholder col-3 bg-primary"></div>
    <div class="placeholder col-12 bg-dark"></div>
    <div class="placeholder col-12 bg-dark"></div>
  </div>
</template>

<script setup>
</script>

<style scoped>
.skeleton {
  background-color: #e0e0e0;
  border-radius: 4px;
}
.skeleton-text {
  width: 100%;
  height: 1.2em;
}
</style>
