<template>
    <div class="container">
      <div>
        <br>
        <br>
        <div v-for="item in selfAssessments" :key="item.id">
          <selfAssessment :item="item" />
        <br>
        </div>
      </div>
    </div>
  </template>


  <script setup>
     import {useUsers} from "../../api";
     import {toRefs, watchEffect} from "vue";
     import SelfAssessment from "./SelfAssessment.vue";

  const props = defineProps({
    participantId: {
        type: Number,
        required: true
      }
    })
    const { participantId } = toRefs(props);
    const { fetchSelfAssessments, selfAssessments } = useUsers();
    const fetchSelfAssessmentsData = () => {
      fetchSelfAssessments(participantId.value)
    }

    watchEffect(fetchSelfAssessmentsData);
  </script>

  <style scoped>
  /* Añade estilos aquí si es necesario */
  </style>
